'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowActiveParkersController', [
  'Data',
  '$stateParams',
  '$scope',
  '$log',
  function (Data, $stateParams, $scope, $log) {
    var eventId = ($scope.eventId = $stateParams.eventId)

    function init () {
      $scope.loadingActiveParkers = true

      Data.getActiveParkers(eventId, $scope.selectedLot.id).then(function (
        parkers
      ) {
        $scope.loadingActiveParkers = false
        $scope.activeParkers = _.map(parkers, function (parker) {
          var vehicle = _.find(parker.vehicles, {
            id: parker.selectedVehicleId
          })

          return { id: parker.id, vehicle: vehicle }
        })
      })
    }

    init()
  }
])
