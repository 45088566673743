'use strict'
import angular from 'angular'
import _ from 'lodash'
import moment from 'moment'

const app = angular.module('citifydMonitoring')

app.factory('Data', function DataFactory (
  $q,
  $log,
  $http,
  $timeout,
  $window,
  $httpParamSerializer,
  ENV,
  Authentication,
  $i18next
) {
  function updateCurrentUser (data) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/me',
      data: data
    }).then(function (response) {
      return response.data.user
    })
  }

  function getGatesSummary (eventId) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/events/' + eventId + '/gates-summary'
    }).then(function (response) {
      return response.data.lots
    })
  }

  function getGateInfo (eventId, eventGateId, options) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/gates/' +
        eventGateId +
        '/entries',
      params: options
    })
  }

  function closeoutCashier (eventId, data) {
    return $http({
      method: 'POST',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/events/' + eventId + '/closeout-cashier',
      data: data
    })
  }

  function updateEvent (eventId, data) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/events/' + eventId,
      data: data
    })
  }

  function requestPasswordReset (email) {
    return $http({
      method: 'POST',
      url: `${ENV.apiUrl}/password`,
      data: { email: email }
    })
  }

  function getParkingData (eventId) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/monitoring?include_waitlist_entries=true'
    })
  }

  function searchParkers (eventId, filters) {
    var qs = $httpParamSerializer(filters)

    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/parkers' +
        (qs ? '?' + qs : '')
    })
  }

  function addVehicle (eventId, userId, vehicleData) {
    return $http({
      method: 'POST',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/parkers/' +
        userId +
        '/vehicles',
      data: { vehicle: vehicleData }
    })
  }

  function getNextEvents () {
    return $http({
      method: 'GET',
      params: { filter: 'future' },
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/events'
    })
  }

  function updateAllocation (availabilityId, lotId, spaces) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/me/availabilities/' + availabilityId + '/' + lotId,
      data: {
        spaces: [{ name: 'regular', max: spaces }, { name: 'ada', max: 0 }]
      }
    })
  }

  function startReservation (userId, ticketId, vehicleId) {
    return $http({
      method: 'POST',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/me/reservations',
      data: {
        targetUserId: userId,
        ticketId: ticketId,
        vehicleId: vehicleId
      }
    })
  }

  function updateTicketVehicle (eventId, userId, data) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/events/' + eventId + '/parkers/' + userId + '/ticket-vehicle',
      data: data
    })
  }

  function fetchParkerGateEntry (eventId, userId) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/parkers/' +
        userId +
        '/gate-entry'
    })
  }

  function updateParkerGateEntry (eventId, userId, eventGateId) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url:
        ENV.apiUrl +
        '/admin/events/' +
        eventId +
        '/parkers/' +
        userId +
        '/gate-entry',
      data: { eventGateId: eventGateId }
    })
  }

  function getLots () {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      params: { all: true },
      url: ENV.apiUrl + '/admin/lots'
    })
  }

  function getLotInfo (lotId) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/me/lots/' + lotId
    })
  }

  function getLotReservations (options) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/snapshot/parkers',
      params: options
    })
  }

  function getLotSchedule (lotId, rangeStart, rangeEnd) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/lots/' + lotId + '/schedule',
      params: { rangeStart: rangeStart, rangeEnd: rangeEnd }
    })
  }

  function getLotSubscriptions (lotId, showActiveOnly) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/lots/' + lotId + '/subscriptions'
    })
  }

  function getAllSubscriptions (options) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/lots/subscriptions',
      params: options
    })
  }

  function updateLotPlan (planId, data) {
    return $http({
      method: 'PUT',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/plans/' + planId,
      data: { plan: data }
    })
  }

  function checkLotsPlans () {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/lots/check-plans'
    })
  }

  function getOrganizations () {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/organizations'
    })
  }

  function getActiveParkers (eventId, lotId) {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/admin/events/' + eventId + '/parkers',
      params: { active: true, lotId: lotId }
    }).then(function (response) {
      return response.data.users
    })
  }

  function getConnectApiToken () {
    return $http({
      method: 'GET',
      headers: Authentication.mountAuthorizationHeaders(),
      url: ENV.apiUrl + '/applications/connect'
    })
  }

  function showErrorAlert (response) {
    var message = _.get(response, 'error.message')

    if (!message) {
      message = $i18next.t('commonErrors.connectionProblem')
    }

    $timeout(function () {
      $window.alert(message)
    }, 50)
  }

  // Get timezoneName based on a coordinate
  // You can pass two arguments containing the latitude and longitude,
  // or one argument that is an object with `latitude` and `longitude` keys
  function getTimezoneNameFromCoordinates (latitude, longitude) {
    var DEFAULT_TIMEZONE = 'America/Los_Angeles'

    var logError = function () {
      $log.error(
        'Error trying to retrieve timezone from coordinates (' +
          latitude +
          ', ' +
          longitude +
          '). Defaulting to "' +
          DEFAULT_TIMEZONE +
          '"'
      )
    }

    var url =
      'https://maps.googleapis.com/maps/api/timezone/json?location=' +
      latitude +
      ',' +
      longitude +
      '&timestamp=' +
      moment().unix() +
      '&key=' +
      ENV.googleMapsApiKey

    var promise = $http({
      method: 'GET',
      url: url,
      headers: {
        'Citifyd-app-version': undefined
      }
    })

    return promise.then(
      function (response) {
        var timezoneName = _.get(response, 'data.timeZoneId')
        if (!timezoneName) {
          logError()
        }

        return _.get(response, 'data.timeZoneId', DEFAULT_TIMEZONE)
      },

      function () {
        logError()
        return DEFAULT_TIMEZONE
      }
    )
  }

  function localizeSystemGateName (gate) {
    if (!gate.isSystemGate) {
      return gate.name
    }

    const cleanName = gate.name.toLowerCase().replace(/ /g, '_')
    return $i18next.t(`systemGates.${cleanName}`)
  }

  return {
    updateCurrentUser: updateCurrentUser,
    getGatesSummary: getGatesSummary,
    getGateInfo: getGateInfo,
    closeoutCashier: closeoutCashier,
    getNextEvents: getNextEvents,
    updateEvent: updateEvent,
    getParkingData: getParkingData,
    searchParkers: searchParkers,
    updateAllocation: updateAllocation,
    requestPasswordReset: requestPasswordReset,
    addVehicle: addVehicle,
    getActiveParkers: getActiveParkers,

    startReservation: startReservation,
    updateTicketVehicle: updateTicketVehicle,

    fetchParkerGateEntry: fetchParkerGateEntry,
    updateParkerGateEntry: updateParkerGateEntry,

    getLots: getLots,
    getLotInfo: getLotInfo,
    getLotReservations: getLotReservations,
    getLotSchedule: getLotSchedule,
    getLotSubscriptions: getLotSubscriptions,
    getAllSubscriptions: getAllSubscriptions,
    updateLotPlan: updateLotPlan,
    checkLotsPlans: checkLotsPlans,

    getOrganizations: getOrganizations,

    getConnectApiToken: getConnectApiToken,

    showErrorAlert: showErrorAlert,
    getTimezoneNameFromCoordinates: getTimezoneNameFromCoordinates,
    localizeSystemGateName: localizeSystemGateName
  }
})
