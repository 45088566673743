'use strict'
import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.directive('ctFocusOn', function () {
  return function (scope, elem, attr) {
    scope.$on(attr.ctFocusOn, function () {
      elem[0].focus()
    })
  }
})
