'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('SubscriptionsIndexController',
  function (
    Data,
    $i18next,
    $interval,
    $log,
    $scope,
    $stateParams,
    $timeout,
    $window
  ) {
    const organizationId = $stateParams.organizationId

    let searchTimer = null
    let autoRefreshInterval = null

    // When the user types "citifydlatererror" into the search bar, it will trigger
    // an error when erasing the search bar content. This is for allowing the testing
    // of the behavior. This variable is using as a flag for activating this behavior.
    let allSubscriptionsReturnErrorTest = false

    function transformSubscriptions (subscriptions, lots) {
      // Transforms
      // [{ "id": 1, "name": "foo" }, { "id": 2, "name": "bar" }]
      // into
      // { "1": { "id": 1, "name": "foo" }, "2": { "id": 2, "name": "bar" } }
      const lotsById = _(lots).groupBy(lot => lot.id).mapValues(lot => lot[0]).value()

      for (const subscription of subscriptions) {
        for (const vehicle of subscription.vehicles) {
          vehicle.license = vehicle.license.replace(/ /g, '').toUpperCase()
          vehicle.makeAndModel = `${vehicle.make || ''} ${vehicle.model || ''}`.trim()
        }
      }

      return _(subscriptions)
        .groupBy(sub => sub.plan.lot.id)
        .map((subscriptions, lotId) => ({
          lot: lotsById[lotId],
          subscriptions
        }))
        .value()
    }

    function getParsedQuery () {
      return $scope.data.search.query.trim().toUpperCase()
    }

    function load ({ mode, query, isAutoRefresh = false }) {
      if (isAutoRefresh) {
        $scope.data[mode].isAutoRefreshing = true
      } else {
        $scope.data[mode].isLoading = true
        $scope.data[mode].loadingError = false
      }

      // For explaination of this part of the code, go to the
      // allSubscriptionsReturnErrorTest variable definition.
      if (mode === 'all' && allSubscriptionsReturnErrorTest) {
        allSubscriptionsReturnErrorTest = false
        query = 'CITIFYDERRORTEST' // back-end returns error when this query is passed
      }

      Data
        .getAllSubscriptions({
          ...(query !== '' && { search: query }),
          ...(organizationId && { organizationId })
        })
        .then(response => {
          // Ignore if search query has changed while it was loading...
          if ($scope.mode === 'search' && getParsedQuery() !== query) {
            $log.log('Subscription result ignored because search query has changed.')
            return
          }

          if (isAutoRefresh) {
            $scope.data[mode].isAutoRefreshing = false
          } else {
            $scope.data[mode].isLoading = false
          }

          $scope.data[mode].subscriptionsCount = response.data.subscriptions.length
          $scope.data[mode].subscriptions = transformSubscriptions(response.data.subscriptions, response.data.lots)
          $scope.data[mode].lastUpdate = moment().format()

          $scope.loadedInitialData = true
          $scope.lastAutorefreshFailed = false

          // For explaination of this part of the code, go to the
          // allSubscriptionsReturnErrorTest variable definition.
          if ($scope.mode === 'search' && getParsedQuery() === 'CITIFYDLATERERROR') {
            allSubscriptionsReturnErrorTest = true
          }
        })
        .catch(() => {
          if (isAutoRefresh) {
            $scope.lastAutorefreshFailed = true
            $scope.data[mode].isAutoRefreshing = false
          } else {
            $scope.data[mode].isLoading = false

            if (mode === 'all' && $scope.loadedInitialData) {
              $timeout(() => {
                $window.alert($i18next.t('subscriptions.index.loadingErrorWithFallbackToPreviouslyLoadedData'))
              }, 30)
            } else {
              $scope.data[mode].loadingError = true
            }
          }
        })
    }

    $scope.clickedRefresh = function () {
      if ($scope.data[$scope.mode].isLoading) {
        return
      }

      load({ mode: $scope.mode, query: getParsedQuery() })
    }

    $scope.searchQueryChanged = function () {
      $timeout.cancel(searchTimer)

      searchTimer = $timeout(() => {
        $scope.mode = getParsedQuery() === '' ? 'all' : 'search'

        load({
          mode: $scope.mode,
          query: getParsedQuery()
        })
      }, 500)
    }

    function autoRefresh () {
      if (!$scope.data.all.isLoading) {
        load({ mode: 'all', isAutoRefresh: true })
      }

      if ($scope.mode === 'search' && !$scope.data.search.isLoading) {
        load({ mode: 'search', query: getParsedQuery(), isAutoRefresh: true })
      }
    }

    function setUpAutoRefresh () {
      // Auto-refresh list every minute
      autoRefreshInterval = $interval(autoRefresh, 60 * 1000)
      $scope.$on('$destroy', () => $interval.cancel(autoRefreshInterval))

      // Auto-refresh when going from offline to online (with 5s limitation)
      let lastOnlineAutorefresh = null
      let onlineAutorefreshEventListener = () => {
        $log.log('Online event triggered')

        const now = new Date()

        if (lastOnlineAutorefresh === null || lastOnlineAutorefresh - now >= 5) {
          lastOnlineAutorefresh = now
          autoRefresh()
        }
      }
      $window.addEventListener('online', onlineAutorefreshEventListener)
      $scope.$on('$destroy', () => $window.removeEventListener('online', onlineAutorefreshEventListener))
    }

    function init () {
      $scope.data = {
        all: {
          subscriptions: [],
          subscriptionsCount: 0,
          isLoading: true,
          isAutoRefreshing: false,
          lastUpdate: null,
          loadingError: false
        },
        search: {
          subscriptions: [],
          subscriptionsCount: 0,
          isLoading: false,
          isAutoRefreshing: false,
          lastUpdate: null,
          query: '',
          loadingError: false
        }
      }

      $scope.mode = 'all'
      $scope.loadedInitialData = false
      $scope.lastAutorefreshFailed = false

      load({ mode: 'all' })

      setUpAutoRefresh()
    }

    init()
  }
)
