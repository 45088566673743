'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowParkersShowParkerGateController',
  function (Data, $stateParams, $rootScope, $timeout, $scope, $log, $i18next) {
    var eventId = parseInt($stateParams.eventId, 10)
    var parkerId = parseInt($stateParams.parkerId, 10)

    var gateUpdateTimer
    var scopeDestroyed = false

    $scope.editingParkerGate = false
    $scope.eventGates = []

    $scope.gateEntryDescription = function () {
      if (!_.get($scope.selectedParker, 'gateEntry')) {
        return ''
      }

      const gateName = _.get($scope.selectedParker, 'gateEntry.eventGate.name')
      const lotName = _.get($scope.selectedParker, 'gateEntry.eventGate.gate.lot.name')
      const isManuallyCreated = _.get($scope.selectedParker, 'gateEntry.isManuallyCreated')
      const isSystemGate = _.get($scope.selectedParker, 'gateEntry.eventGate.gate.isSystemGate')
      const isExternal = _.get($scope.selectedParker, 'gateEntry.isExternal')
      const options = { gateName, lotName }

      if (isManuallyCreated) {
        return $i18next.t(
          'events.show.parkers.show.parkerGate.gateEntryDescription.manual',
          options
        )
      } else if (!isSystemGate) {
        if (isExternal) {
          return $i18next.t(
            'events.show.parkers.show.parkerGate.gateEntryDescription.external',
            { ...options, source: 'Ticketmaster' }
          )
        } else {
          return $i18next.t(
            'events.show.parkers.show.parkerGate.gateEntryDescription.beacon',
            options
          )
        }
      }
    }

    $scope.openChangeGateForm = function () {
      $scope.editingParkerGate = true
      $scope.selectedParkerGate = null
      $scope.loadingGateChange = false
    }

    $scope.getEventGateDescription = function (eventGateId) {
      var eventGate = _.find($scope.eventGates, { id: eventGateId })
      return (
        _.get(eventGate, 'name') + ' - ' + _.get(eventGate, 'Gate.Lot.name')
      )
    }

    $scope.changedParkerGate = function () {
      if (_.isEmpty($scope.selectedParkerGate)) {
        return
      }

      if ($scope.selectedParkerGate === 'cancel') {
        $scope.editingParkerGate = false
        return
      }

      updateGate($scope.selectedParkerGate)
    }

    function updateGate (eventGateId) {
      $scope.loadingGateChange = true

      eventGateId = parseInt(eventGateId, 10)

      Data.updateParkerGateEntry(eventId, parkerId, eventGateId).then(
        function () {
          $scope.editingParkerGate = false
          $rootScope.$broadcast('reloadParker')

          var unbind = $scope.$on('parkerReloaded', function () {
            fetchGateEntry()
            unbind()
          })
        },
        function (response) {
          $scope.loadingGateChange = false
          $scope.selectedParkerGate = null

          Data.showErrorAlert(response.data)
        }
      )
    }

    function fetchGateEntry () {
      if (scopeDestroyed) {
        return
      }

      Data.fetchParkerGateEntry(eventId, parkerId)
        .then(function (response) {
          $scope.selectedParker.gateEntry = response.data.gateEntry
        })
        .finally(setGateUpdateTimer)
    }

    function setGateUpdateTimer () {
      $timeout.cancel(gateUpdateTimer)

      if (scopeDestroyed) {
        return
      }

      gateUpdateTimer = $timeout(
        fetchGateEntry,
        _.get($scope.selectedParker, 'gateEntry') ? 30000 : 5000
      )
    }

    function init () {
      var unbind = $scope.$watch('selectedParker', function () {
        if ($scope.selectedParker) {
          fetchGateEntry()
          unbind()
        }
      })

      Data.getParkingData(eventId).then(function (response) {
        $scope.eventGates = response.data.eventGates
      })

      $scope.$on('$destroy', function () {
        scopeDestroyed = true
        $timeout.cancel(gateUpdateTimer)
        $log.info('ParkerGateCtrl scope destroyed')
      })
    }

    init()
  }
)
