'use strict'
import angular from 'angular'
import _ from 'lodash'
import moment from 'moment'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowController',
  function (
    ENV,
    Authentication,
    Data,
    $stateParams,
    $timeout,
    $interval,
    $window,
    $location,
    $scope,
    $state,
    $i18next
  ) {
    var destroyed = false
    var refreshTimer
    var eventId = $stateParams.eventId
    $scope.eventId = eventId

    $scope.canSeeUserInformation = Authentication.hasPermission('view-user-information')
    $scope.togglingWaitlist = false
    $scope.barcodes = 0
    $scope.waitlistEntries = 0
    $scope.savingLot = false
    $scope.waitlistOverlay = false
    $scope.preloaded = false
    $scope.selectedLot = null
    $scope.updatedAt = null
    $scope.lastScansUpdate = null
    $scope.eventDate = null
    $scope.eventTimezoneName = null
    $scope.eventGates = []
    $scope.autoRedirect = $location.search().autoredirect
    $scope.showTicketMasterUpdate = true

    function updateSelectedLotData () {
      if (!$scope.selectedLot) {
        return
      }

      var lot = _.find($scope.lots, { id: $scope.selectedLot.id })

      if (lot) {
        _.forEach(['sold', 'activated', 'parked'], function (info) {
          $scope.selectedLot[info] = lot[info]
        })
      }
    }

    $scope.isSoldOut = function (lot) {
      return lot.sold === lot.available
    }

    $scope.isState = function (state, exact) {
      return exact ? $state.is(state) : $state.includes(state)
    }

    $scope.refresh = function () {
      $timeout.cancel(refreshTimer)

      return Data.getParkingData(eventId).then(
        function (response) {
          $scope.preloaded = true
          $scope.updatedAt = new Date()

          if (!$scope.togglingWaitlist) {
            $scope.waitlistOpen = response.data.waitlistOpen
            $scope.waitlistEnabled = response.data.waitlistEnabled
          }

          $scope.eventName = response.data.name
          $scope.eventVenue = response.data.venue
          $scope.eventDate = response.data.start
          $scope.eventTimezoneName = response.data.timezoneName
          $scope.lastScansUpdate = response.data.lastScanUpdate
          $scope.barcodes = response.data.amountOfBarcodes
          $scope.beaconTriggers = response.data.amountOfBeaconTriggers
          $scope.eventGates = response.data.eventGates

          $scope.amountOfWaitlistEntries =
            response.data.amountOfWaitlistEntries
          $scope.waitlistEntries = response.data.waitlistEntries
          $scope.lots = response.data.lots

          updateSelectedLotData()

          if (!destroyed) {
            refreshTimer = $timeout($scope.refresh, ENV.refreshInterval)
          }
        },

        function () {
          if (!destroyed) {
            refreshTimer = $timeout($scope.refresh, ENV.refreshInterval)
          }
        }
      )
    }

    $scope.getWaitlistEntryStatusText = function (waitlistEntry) {
      const status = $scope.getWaitlistEntryStatus(waitlistEntry)

      if (status === 'active') {
        var diff = moment(waitlistEntry.expiresAt).diff(moment())
        var duration = moment.duration(diff)
        var hours = duration.get('hours')
        var minutes = duration.get('minutes')
        minutes = minutes < 10 ? '0' + minutes : minutes

        return $i18next.t(
          `events.show.waitlist.statuses.activeTimeLeft`,
          { hours, minutes }
        )
      } else {
        return $i18next.t(`events.show.waitlist.statuses.${status}`)
      }
    }

    $scope.getWaitlistEntryStatus = function (waitlistEntry) {
      if (waitlistEntry.TicketId) {
        return 'purchased'
      } else if (waitlistEntry.deletedAt) {
        return 'lapsed'
      } else if (waitlistEntry.activatedAt === null) {
        return 'onWaitlist'
      } else {
        return 'active'
      }
    }

    $scope.waitlistEntryClasses = function (waitlistEntry) {
      const status = $scope.getWaitlistEntryStatus(waitlistEntry)

      return {
        purchased: status === 'purchased',
        lapsed: status === 'lapsed'
      }
    }

    $scope.increaseAllocation = function () {
      if (!$scope.hasReachedMaximumAmountFor($scope.selectedLot)) {
        $scope.selectedLot.available++
      }
    }

    $scope.hasReachedMaximumAmountFor = function (selectedLot, ignoreLast) {
      var max = selectedLot.maxSpots + (ignoreLast ? 1 : 0)
      return selectedLot.available >= max
    }

    $scope.hasReachedMinimumAmountFor = function (selectedLot, ignoreFirst) {
      if (ignoreFirst) {
        return selectedLot.available < selectedLot.sold
      }

      return selectedLot.available <= selectedLot.sold
    }

    $scope.decreaseAllocation = function () {
      if ($scope.selectedLot.available > $scope.selectedLot.sold) {
        $scope.selectedLot.available--
      }
    }

    $scope.selectLot = function (lot) {
      $scope.selectedLot = angular.copy(lot)
    }

    $scope.saveLot = function () {
      // check if the user has typed more or less spaces than available
      if (
        $scope.hasReachedMaximumAmountFor($scope.selectedLot, true) ||
        $scope.hasReachedMinimumAmountFor($scope.selectedLot, true)
      ) {
        return
      }

      $scope.savingLot = true

      Data.updateAllocation(
        $scope.selectedLot.availabilityId,
        $scope.selectedLot.id,
        $scope.selectedLot.available
      ).then(
        function () {
          $scope.refresh().then($scope.closeLot, $scope.closeLot)
        },
        function (response) {
          $scope.savingLot = false

          var message = ''

          if (response.data && response.data.error) {
            message = response.data.error.message
          } else {
            message = $i18next.t('commonErrors.connectionProblem')
          }

          $timeout(function () {
            $window.alert(message)
          }, 50)
        }
      )
    }

    $scope.shouldShowWaitlistToggle = function () {
      var loggedUser = Authentication.getLoggedUser()

      if (!loggedUser.isAdmin && loggedUser.venueId !== $scope.eventVenue.id) {
        return false
      }

      return !$scope.isAfterTipoff()
    }

    $scope.isAfterTipoff = function () {
      return moment().isAfter($scope.eventDate)
    }

    $scope.waitlistLink = function () {
      $scope.waitlistOverlay = true
    }

    $scope.closeWaitlist = function () {
      $scope.waitlistOverlay = false
    }

    $scope.closeLot = function () {
      $scope.savingLot = false
      $scope.selectedLot = null
    }

    $scope.shouldShowWaitlist = function () {
      return (
        $location.search().show_waitlist || $scope.waitlistEntries.length > 0
      )
    }

    $scope.toggleWaitlistEnabled = function () {
      $scope.togglingWaitlist = true

      Data.updateEvent(eventId, {
        event: { waitlistEnabled: $scope.waitlistOpen }
      }).finally(function () {
        $scope.togglingWaitlist = false
      })
    }

    $scope.$on('$destroy', function () {
      destroyed = true
      $timeout.cancel(refreshTimer)
    })

    if (Authentication.isLoggedIn()) {
      $scope.refresh()
    }
  }
)
