'use strict'

import angular from 'angular'
import moment from 'moment-timezone'

const app = angular.module('citifydMonitoring')

app.directive('ctEventInfo', function () {
  return {
    restrict: 'E',
    templateUrl: '/components/event-info/event-info.template.html',
    scope: {
      eventDate: '=',
      eventName: '=',
      eventTimezoneName: '=',
      lastScansUpdate: '='
    },
    controller ($scope) {
      $scope.$watchGroup(['eventDate', 'eventTimezoneName'], () => {
        if ($scope.eventDate && $scope.eventTimezoneName) {
          $scope.eventDateWithTimezone = moment($scope.eventDate).tz($scope.eventTimezoneName)
        }
      })
    }
  }
})
