'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowParkersShowController',
  function (
    Authentication,
    Data,
    $log,
    $stateParams,
    $window,
    $scope,
    $state,
    $timeout,
    $rootScope,
    $i18next
  ) {
    let eventId = parseInt($stateParams.eventId, 10)
    let parkerId = parseInt($stateParams.parkerId, 10)
    let isLoading = false
    let updateTimer = null
    let scopeDestroyed = false
    let lotsCache = {}

    $scope.canSeeUserInformation = Authentication.hasPermission('view-user-information')
    $scope.eventId = eventId
    $scope.parkerId = parkerId
    $scope.selectedParker = null
    $scope.updatingParker = false
    $scope.selectedVehicleId = null
    $scope.fromEventList = $stateParams.fromEventList

    function load ({ inBackground = false } = {}) {
      if (isLoading || scopeDestroyed) {
        return
      }
      isLoading = true

      if (!inBackground) {
        $scope.isLoadingInForeground = true
      }

      return Data
        .searchParkers(eventId, { id: $stateParams.parkerId })
        .then(response => {
          const users = response.data.users
          if (!users[0]) {
            return $state.go('events-show-parkers-index')
          }

          const previouslyLoadedGateEntry = _.get($scope.selectedParker, 'gateEntry')
          $scope.selectedParker = users[0]
          $scope.selectedParker.gateEntry = previouslyLoadedGateEntry

          return $scope.selectedParker.lot.id
        })
        .then(lotId => {
          if (lotsCache[lotId]) {
            return lotsCache[lotId]
          }

          return Data.getLotInfo(lotId).then(response => {
            const lot = response.data.lot
            lotsCache[lotId] = lot
            return lot
          })
        })
        .then(lot => {
          $scope.selectedParker.lot = lot
          $scope.isLoadingInForeground = false
        })
        .catch(err => {
          $log.info('Load error', err)
        })
        .finally(() => {
          isLoading = false
          setUpdateTimer()
        })
    }

    $scope.activatePass = function () {
      // We have to use $timeout here to prevent a bug on Mobile safari that
      // dispatches the click event twice when we show an alert on page.
      $timeout(function () {
        if (!$scope.selectedParker.selectedVehicleId) {
          $window.alert(
            $i18next.t('events.show.parkers.show.main.pleaseSelectVehicleToActivatePass')
          )
          return
        }

        if (
          !$window.confirm(
            $i18next.t('events.show.parkers.show.main.areYouSureActivatePass')
          )
        ) {
          return false
        }

        $scope.updatingParker = true

        return Data.startReservation(
          parkerId,
          $scope.selectedParker.ticketId,
          $scope.selectedParker.selectedVehicleId
        ).then(
          function () {
            $scope.updatingParker = false
            return load()
          },
          function (response) {
            $scope.updatingParker = false
            Data.showErrorAlert(response.data)
          }
        )
      })
    }

    $scope.selectVehicle = function (vehicleId, options = {}) {
      let previousSelectedVehicleId = $scope.selectedParker.selectedVehicleId

      if (options.confirm) {
        const message = $i18next.t('events.show.parkers.show.main.areYouSureChangeVehicle')
        if (!$window.confirm(message)) {
          return
        }
      }

      $scope.updatingParker = true
      $scope.selectedParker.selectedVehicleId = vehicleId

      Data.updateTicketVehicle(
        eventId,
        parkerId,
        {
          vehicleId: $scope.selectedParker.selectedVehicleId,
          vehicleSelectionConfirmed: true
        }
      ).then(
        function () {
          $scope.updatingParker = false
          return load()
        },
        function (response) {
          $scope.updatingParker = false
          $scope.selectedParker.selectedVehicleId = previousSelectedVehicleId

          Data.showErrorAlert(response.data)
        }
      )
    }

    function setUpdateTimer () {
      $timeout.cancel(updateTimer)

      if (scopeDestroyed) {
        return
      }

      updateTimer = $timeout(() => load({ inBackground: true }), 5 * 1000)
    }

    function setBackLinks () {
      let params
      if ($rootScope.previousState.name === 'events-show-parkers-search') {
        params = $rootScope.previousState.params
      } else {
        params = { eventId }
      }

      $scope.searchButtonLink = `events-show-parkers-search(${JSON.stringify(params)})`
      $scope.closeButtonLink = $scope.searchButtonLink
    }

    function init () {
      setBackLinks()

      $scope.$on('$destroy', function () {
        scopeDestroyed = true
        if (updateTimer) {
          $timeout.cancel(updateTimer)
        }
        $log.info('EventsShowParkersShowController scope destroyed')
      })

      $scope.$on('reloadParker', function (e, newlyAddedVehicleId) {
        load().then(function () {
          if (newlyAddedVehicleId && $scope.selectedParker) {
            $scope.selectVehicle(newlyAddedVehicleId)
          }

          $scope.$broadcast('parkerReloaded')
        })
      })

      load()
    }

    init()
  }
)
