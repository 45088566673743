'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('PropertiesIndexController',
  function (
    ENV,
    Data,
    Authentication,
    $scope,
    $state,
    $stateParams,
    $q
  ) {
    let loggedEntity = Authentication.getLoggedEntity()

    $scope.appVersion = ENV.appVersion
    $scope.isLoading = false
    $scope.supportNumber = ENV.supportNumber
    $scope.subscriptionsPageLink = null
    $scope.returningUser = Boolean($stateParams.returningUser)
    $scope.initialResultsLimit = 3
    $scope.isAdmin = Authentication.isAdmin()

    $scope.isSameDayAsToday = function (date) {
      return moment().isSame(date, 'day')
    }

    function getShowSubscriptionsPagePromise () {
      if ($scope.isAdmin) {
        return $q(resolve => resolve(true))
      }

      return Data.checkLotsPlans().then(response => response.data.hasPlans)
    }

    function loadForOrganization () {
      const { permissions } = loggedEntity.data.currentSession
      const monitorEventPermission = _.find(permissions, p => p.key === 'monitor-events')

      if (monitorEventPermission) {
        $state.go('events-show', { eventId: monitorEventPermission.filter.eventId }, { location: 'replace' })
      } else {
        Data.showErrorAlert()
      }
    }

    function loadForUser () {
      const getEventsPromise = Data.getNextEvents()
      const getLotsPromise = Data.getLots()
      const subscriptionsPagePromise = getShowSubscriptionsPagePromise()

      getEventsPromise.then(function (response) {
        $scope.events = response.data.events.map(event => {
          event.start = moment(event.start).tz(event.timezoneName)
          return event
        })
      }, Data.showErrorAlert)

      getLotsPromise.then(function (response) {
        $scope.lots = response.data.lots.filter(lot => lot.isApproved)
      }, Data.showErrorAlert)

      subscriptionsPagePromise.then(show => {
        if (show) {
          $scope.subscriptionsPageLink = $scope.isAdmin ? 'subscriptions-select-organization' : 'subscriptions'
        }
      })

      $q.all([getLotsPromise, getEventsPromise, subscriptionsPagePromise]).then(function () {
        $scope.isLoading = false
      })
    }

    function load () {
      $scope.isLoading = true

      if (loggedEntity.type === 'organization') {
        loadForOrganization()
      } else {
        loadForUser()
      }
    }

    load()
  }
)
