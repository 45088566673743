'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.factory('Authentication', function AuthenticationFactory (
  ENV,
  Languages,
  $q,
  $http,
  $window,
  $cookies,
  uuid
) {
  const ACCESS_TOKEN_KEY_NAME = 'citifyd_access_token'

  let loggedEntity = null
  let sessionRenewalInterval = null

  function getClientToken () {
    var token = $window.localStorage.getItem('citifydClientToken')

    if (!token) {
      token = uuid.v4()
      $window.localStorage.setItem('citifydClientToken', token)
    }

    return token
  }

  function isLoggedIn () {
    return Boolean(loggedEntity)
  }

  function getLoggedUser () {
    return loggedEntity && loggedEntity.type === 'user' ? loggedEntity.data : null
  }

  function getLoggedOrganization () {
    return loggedEntity && loggedEntity.type === 'organization' ? loggedEntity.data : null
  }

  function getLoggedEntity () {
    return loggedEntity
  }

  function hasPermission (permissionName) {
    return loggedEntity && _.includes(loggedEntity.permissions, permissionName)
  }

  function isAdmin () {
    const user = getLoggedUser()
    return user && user.isAdmin
  }

  function logout () {
    loggedEntity = null

    if ($window.localStorage) {
      $window.localStorage.removeItem(ACCESS_TOKEN_KEY_NAME)
    } else {
      $cookies.remove(ACCESS_TOKEN_KEY_NAME)
    }

    endSessionRenewalInterval()
  }

  function persistToken (token) {
    if ($window.localStorage) {
      $window.localStorage.setItem(ACCESS_TOKEN_KEY_NAME, token)
    } else {
      $cookies.put(ACCESS_TOKEN_KEY_NAME, token)
    }
  }

  function getPersistedToken () {
    if ($window.localStorage) {
      return $window.localStorage.getItem(ACCESS_TOKEN_KEY_NAME)
    }

    return $cookies.get(ACCESS_TOKEN_KEY_NAME)
  }

  function setToken (token, loggedInCallback, notLoggedInCallback) {
    persistToken(token)
    verifyAuthentication(loggedInCallback, notLoggedInCallback)
  }

  function startSessionRenewalInterval () {
    endSessionRenewalInterval()

    sessionRenewalInterval = setInterval(
      function () {
        renewSession()
      },
      10 * 60 * 1000 // 10 minutes
    )
  }

  function endSessionRenewalInterval () {
    if (sessionRenewalInterval !== null) {
      clearInterval(sessionRenewalInterval)
      sessionRenewalInterval = null
    }
  }

  function renewSession () {
    var token = getPersistedToken()

    if (!token) {
      return $q(function (resolve, reject) {
        resolve(false)
      })
    }

    var request = $http({
      method: 'PUT',
      url: ENV.apiUrl + '/me/sessions/current',
      headers: mountAuthorizationHeaders({}, token)
    })

    return request.then(function (response) {
      if (response.data.renewed) {
        persistToken(response.data.accessToken)
        return true
      }

      return false
    })
  }

  function tryAuthenticate (data) {
    data = _.clone(data)
    data.sessionType = 'monitoring'

    var request = $http({
      method: 'POST',
      url: `${ENV.apiUrl}/login?loadPermissions=true`,
      data: data,
      headers: {
        'Citifyd-client-token': getClientToken()
      }
    })

    return request.then(response => {
      if (response.data.user) {
        loggedEntity = { type: 'user', data: response.data.user, permissions: response.data.permissions }
        const loggedUser = loggedEntity.data

        persistToken(loggedUser.accessToken)
        startSessionRenewalInterval()

        // Customers can change their preferred language before login, and this change should be persisted.
        // In case this happens, we update the user language here.
        const language = Languages.getCurrent()
        if (language !== loggedUser.language) {
          if (Languages.hasUserUpdated()) {
            _updateUserLanguage(language)
          } else {
            Languages.change(loggedUser.language)
          }
        }
      }

      return response.data
    })
  }

  function _updateUserLanguage (language) {
    return $http({
      method: 'PUT',
      url: ENV.apiUrl + '/me',
      data: { language },
      headers: mountAuthorizationHeaders()
    })
  }

  function verifyAuthentication (loggedInCallback, notLoggedInCallback) {
    var token = getPersistedToken()

    if (!token) {
      notLoggedInCallback()
      return
    }

    $http({
      method: 'GET',
      headers: mountAuthorizationHeaders({}, token),
      url: ENV.apiUrl + '/me?loadPermissions=true'
    }).then(
      function (response) {
        if (response.data.user) {
          loggedEntity = { type: 'user', data: response.data.user }
        } else if (response.data.organization) {
          loggedEntity = { type: 'organization', data: response.data.organization }
        }

        loggedEntity.permissions = response.data.permissions

        if (loggedEntity.data.currentSession.type === 'management') {
          createMonitoringSessionFromManagementSession(token, loggedInCallback, notLoggedInCallback)
          return
        }

        if (loggedEntity.data.currentSession.type !== 'monitoring') {
          logout()
          notLoggedInCallback()
          return
        }

        renewSession()
        startSessionRenewalInterval()

        loggedInCallback()
      },
      function () {
        notLoggedInCallback()
      }
    )
  }

  function mountAuthorizationHeaders (headers, accessToken) {
    headers = headers || {}
    accessToken = accessToken || _.get(loggedEntity, 'data.accessToken')

    if (accessToken) {
      headers.Authorization = 'Bearer ' + accessToken
    }

    headers['Citifyd-client-token'] = getClientToken()

    return headers
  }

  function createMonitoringSessionFromManagementSession (token, loggedInCallback, notLoggedInCallback) {
    var request = $http({
      method: 'POST',
      url: ENV.apiUrl + '/me/sessions/current/new',
      data: {
        session: { sessionType: 'monitoring' }
      },
      headers: mountAuthorizationHeaders({}, token)
    })

    return request.then(
      function (response) {
        persistToken(response.data.accessToken)
        verifyAuthentication(loggedInCallback, notLoggedInCallback)
      },

      function () {
        logout()
        notLoggedInCallback()
      }
    )
  }

  return {
    setToken,
    isLoggedIn,
    getLoggedUser,
    getLoggedOrganization,
    getLoggedEntity,
    hasPermission,
    isAdmin,
    logout,
    tryAuthenticate,
    verifyAuthentication,
    mountAuthorizationHeaders
  }
})
