'use strict'

import angular from 'angular'
import _ from 'lodash'
import moment from 'moment-timezone'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowParkersIndexController',
  function (
    Data,
    $log,
    $i18next,
    $interval,
    $location,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $window
  ) {
    let eventId = $stateParams.eventId
    let searchTimer = null
    let autoRefreshInterval = null

    $scope.eventId = eventId
    $scope.filterName = ''
    $scope.autoRedirect = $location.search().autoredirect
    $scope.parkers = []
    $scope.lastFilterChange = null
    $scope.noResults = false
    $scope.closeButtonLink = `events-show({eventId: ${eventId}})`

    function buildFiltersObject () {
      const filters = {}
      const name = $scope.data.search.query.trim()

      if (name !== '') {
        filters.name = name
      }

      if ($scope.data.search.selectedLotId) {
        filters.lotId = $scope.data.search.selectedLotId
      }

      return filters
    }

    function transformParkers (parkers) {
      for (const parker of parkers) {
        parker.selectedVehicle = _.find(parker.vehicles, v => v.id === parker.selectedVehicleId)
        parker.foundByOtherVehicle = _.find(parker.vehicles, v => v.foundByPlate && v.id !== parker.selectedVehicleId)
      }

      // Order first by gate entry (parkers without gate entry appear first), then by name.
      return _.orderBy(
        parkers,
        [
          parker => Boolean(parker.gateEntry),
          parker => parker.name
        ],
        ['asc', 'asc']
      )
    }

    function load ({ mode, filters, isAutoRefresh = false }) {
      if (isAutoRefresh) {
        $scope.data[mode].isAutoRefreshing = true
      } else {
        $scope.data[mode].isLoading = true
        $scope.data[mode].loadingError = false
      }

      Data
        .searchParkers(eventId, filters)
        .then(response => {
          const updatedFilters = buildFiltersObject()

          // Ignore if search query has changed while it was loading...
          if ($scope.mode === 'search' && !_.isEqual(filters, updatedFilters)) {
            $log.log('Parkers result ignored because search query has changed.')
            return
          }

          if (isAutoRefresh) {
            $scope.data[mode].isAutoRefreshing = false
          } else {
            $scope.data[mode].isLoading = false
          }

          $scope.data[mode].parkersCount = response.data.users.length
          $scope.data[mode].parkers = transformParkers(response.data.users)
          $scope.data[mode].lastUpdate = moment().format()

          $scope.loadedInitialData = true
          $scope.lastAutorefreshFailed = false
        })
        .catch(err => {
          $log.error('error', err)

          if (isAutoRefresh) {
            $scope.lastAutorefreshFailed = true
            $scope.data[mode].isAutoRefreshing = false
          } else {
            $scope.data[mode].isLoading = false

            if (mode === 'all' && $scope.loadedInitialData) {
              $timeout(() => {
                $window.alert($i18next.t('events.show.parkers.index.loadingErrorWithFallbackToPreviouslyLoadedData'))
              }, 30)
            } else {
              $scope.data[mode].loadingError = true
            }
          }
        })
    }

    $scope.filterChanged = function (filterType) {
      const update = () => {
        const filters = buildFiltersObject()
        $scope.mode = _.isEmpty(filters) ? 'all' : 'search'
        load({ mode: $scope.mode, filters: filters })
      }

      if (filterType === 'name') {
        $state.transitionTo($state.current, { q: $scope.data.search.query }, {
          notify: false,
          inherit: true,
          location: 'replace'
        })

        $timeout.cancel(searchTimer)
        searchTimer = $timeout(update, 500)
      } else {
        update()
      }
    }

    $scope.clickedRefresh = function () {
      if ($scope.data[$scope.mode].isLoading) {
        return
      }

      load({ mode: $scope.mode, filters: buildFiltersObject() })
    }

    $scope.getNoParkersFoundMessage = () => {
      if ($scope.mode === 'search') {
        const filter = buildFiltersObject()

        if (filter.lotId) {
          const lot = _.find($scope.lots, lot => lot.id === filter.lotId)
          const lotName = _.get(lot, 'name')

          if (filter.name) {
            return $i18next.t('events.show.parkers.index.noParkersFound.search.lotAndText', { lotName })
          } else {
            return $i18next.t('events.show.parkers.index.noParkersFound.search.lot', { lotName })
          }
        } else {
          return $i18next.t('events.show.parkers.index.noParkersFound.search.text')
        }
      } else {
        return $i18next.t('events.show.parkers.index.noParkersFound.noSearch')
      }
    }

    function autoRefresh () {
      if (!$scope.data.all.isLoading) {
        load({ mode: 'all', isAutoRefresh: true })
      }

      if ($scope.mode === 'search' && !$scope.data.search.isLoading) {
        const filters = buildFiltersObject()
        load({ mode: 'search', filters: filters, isAutoRefresh: true })
      }
    }

    function setUpAutoRefresh () {
      // Auto-refresh list every minute
      autoRefreshInterval = $interval(autoRefresh, 30 * 1000)
      $scope.$on('$destroy', () => $interval.cancel(autoRefreshInterval))

      // Auto-refresh when going from offline to online (with 5s limitation)
      let lastOnlineAutorefresh = null
      let onlineAutorefreshEventListener = () => {
        $log.log('Online event triggered')

        const now = new Date()

        if (lastOnlineAutorefresh === null || lastOnlineAutorefresh - now >= 5) {
          lastOnlineAutorefresh = now
          autoRefresh()
        }
      }
      $window.addEventListener('online', onlineAutorefreshEventListener)
      $scope.$on('$destroy', () => $window.removeEventListener('online', onlineAutorefreshEventListener))
    }

    function init () {
      $scope.data = {
        all: {
          parkers: [],
          parkersCount: 0,
          isLoading: true,
          isAutoRefreshing: false,
          lastUpdate: null,
          loadingError: false
        },
        search: {
          parkers: [],
          parkersCount: 0,
          isLoading: false,
          isAutoRefreshing: false,
          lastUpdate: null,
          query: $stateParams.q || '',
          selectedLotId: $stateParams.lotId || null,
          loadingError: false
        }
      }

      const currentFilters = buildFiltersObject()

      $scope.mode = _.isEmpty(currentFilters) ? 'all' : 'search'
      $scope.loadedInitialData = false
      $scope.lastAutorefreshFailed = false

      load({
        mode: $scope.mode,
        ...($scope.mode === 'search' && { filters: currentFilters })
      })
      setUpAutoRefresh()

      Data.getParkingData(eventId).then(response => {
        $scope.lots = response.data.lots
      })
    }

    init()
  }
)
