'use strict'
import 'remodal'
import angular from 'angular'
import _ from 'lodash'
import jQuery from 'jquery'

const app = angular.module('citifydMonitoring')

app.factory('CitifydModal', function CitifydModalFactory (ModalService) {
  // Usage:
  //
  // CitifydModal.open('manage-venue-user', { userId: 1 }, function(result) { /* ... */ })
  // in this case, it will open a modal where:
  // - Template: app/views/modals/manage-venue-user.html
  // - Controller: ManageVenueUserModalCtrl (inferred from template name)
  // - "params" on the modal controller will be { userId: 1 }
  // - function(result) { /* ... */ } is the callback to be called when the modal is closed (optional)
  //
  // You may optionally pass the controller name as the 2nd argument, and pass the remaining arguments
  // after it, in this case it will use the controller specified instead of trying to infer its name.
  // Example: CitifydModal.open('manage-venue-user', 'ManageVenueUserModalCtrl', { userId: 1 }
  //   , function(result) { /* ... */ })
  function open (templateName, a, b) {
    var params, controller, callback

    if (_.isString(a) || _.isFunction(a) || _.isArray(a)) {
      controller = a
      params = _.isObject(b) ? b : {}
    } else {
      params = _.isObject(a) ? a : {}
      callback = b
    }

    var templateUrl = getTemplateUrl(templateName)
    controller = controller || templateNameToControllerName(templateName)

    ModalService.showModal({
      templateUrl: templateUrl,
      controller: controller,
      inputs: { params: params }
    }).then(function (modal) {
      modal.element
        .remodal({
          hashTracking: false,
          closeOnOutsideClick: false,
          closeOnEscape: true
        })
        .open()

      disableBackspaceNavigation()

      modal.close.then(function () {
        enableBackspaceNavigation()

        if (_.isFunction(callback)) {
          callback.apply(null, arguments)
        }
      })
    })
  }

  function enableBackspaceNavigation () {
    jQuery(document).off('keydown.disableBackspaceNavigation')
  }

  function disableBackspaceNavigation () {
    enableBackspaceNavigation()

    jQuery(document).on('keydown.disableBackspaceNavigation', function (e) {
      var doPrevent = false

      if (e.keyCode === 8) {
        var d = e.srcElement || e.target
        var tagName = (d.tagName || '').toLowerCase()
        var type = (d.type || '').toLowerCase()

        if (
          tagName === 'textarea' ||
          (tagName === 'input' &&
            _.includes(
              ['email', 'text', 'password', 'date', 'search', 'number', 'tel'],
              type
            ))
        ) {
          doPrevent = d.readOnly || d.disabled
        } else {
          doPrevent = true
        }
      }

      if (doPrevent) {
        e.preventDefault()
      }
    })
  }

  // Gets template URL from template name
  function getTemplateUrl (templateName) {
    return '/modals/' + templateName + '/' + templateName + '.template.html'
  }

  // Transforms template name to controller name
  // e.g. manage-venue-user becomes ManageVenueUserModalCtrl
  function templateNameToControllerName (templateName) {
    var controllerName = templateName.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase()
    })

    return (
      controllerName[0].toUpperCase() +
      controllerName.substr(1) +
      'ModalController'
    )
  }

  return {
    open: open
  }
})
