'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowGatesController',
  function (Data, $stateParams, $scope, $log) {
    var eventId = $stateParams.eventId
    $scope.eventId = eventId

    $scope.unselectGate = function () {
      $scope.selectedGate = null
    }

    $scope.formatBeaconIds = function (ids) {
      return ids.join(', ')
    }

    $scope.selectGate = function (gate) {
      $scope.selectedGate = gate
    }

    function localizeSystemGateName (gate) {
      gate.name = Data.localizeSystemGateName(gate)
      gate.eventGate.name = Data.localizeSystemGateName({
        name: gate.eventGate.name,
        isSystemGate: gate.isSystemGate
      })

      return gate
    }

    function init () {
      $scope.loadingGates = true
      $scope.selectedGate = null

      Data.getGatesSummary(eventId).then(function (lots) {
        $scope.loadingGates = false

        var lot = _.find(lots, { id: $scope.selectedLot.id })
        $scope.gates = _.get(lot, 'gates').map(localizeSystemGateName)
      })
    }

    init()
  }
)
