'use strict'

import angular from 'angular'
import smallLoadingSpinner from '../../assets/images/small-spinner.gif'

const app = angular.module('citifydMonitoring')

app.directive('ctLoadingIndicator', function () {
  return {
    restrict: 'E',
    templateUrl:
      '/components/loading-indicator/loading-indicator.template.html',

    scope: {
      'small': '='
    },

    link: function (scope, element, attrs) {
      scope.hasOverlay = 'withOverlay' in attrs
      scope.smallLoadingSpinnerUrl = smallLoadingSpinner
    }
  }
})
