'use strict'

import angular from 'angular'
import _ from 'lodash'

import { getAcceptedLanguage } from '../../config/i18next.helper'

const app = angular.module('citifydMonitoring')

app.factory('Languages', ($rootScope, $i18next, $http, $cookies) => {
  // we don't use i18next.t('languages.xxx') here because we want
  // the language name in their own language here
  const languages = [
    { key: 'en-US', name: 'English' },
    { key: 'pt-BR', name: 'Português' },
    { key: 'ja-JP', name: '日本語' }
  ]

  let userUpdated = false

  function getAvailable () {
    return languages
  }

  function getCurrent () {
    return $i18next.i18n.language
  }

  function hasUserUpdated () {
    return userUpdated
  }

  function getName (key) {
    return _.get(_.find(languages, { 'key': key }), 'name', null)
  }

  function change (key, manualUpdate = false) {
    if (getCurrent() === key) {
      return
    }

    if (manualUpdate) {
      userUpdated = true
    }

    key = getAcceptedLanguage(key)
    $cookies.put('citifyd_language', key)
    $i18next.i18n.changeLanguage(key)
    $rootScope.$broadcast('language-changed', key)
    $http.defaults.headers.common['Citifyd-accept-language'] = key
  }

  return {
    getAvailable,
    getCurrent,
    getName,
    hasUserUpdated,
    change
  }
})
