'use strict'
import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowParkersShowAddVehicleController',
  function (
    Authentication,
    Settings,
    Data,
    $stateParams,
    $timeout,
    $window,
    $location,
    $scope,
    $rootScope,
    $state
  ) {
    var eventId = parseInt($stateParams.eventId, 10)
    var parkerId = parseInt($stateParams.parkerId, 10)

    $scope.eventId = eventId
    $scope.parkerId = parkerId
    $scope.availableMakes = []
    $scope.availableColors = []
    $scope.continents = []

    $scope.addingVehicle = false

    $scope.newVehicle = {
      color: null,
      makeCode: null,
      license: '',
      countryCode: null, // loadParker will set initial value
      licenseInfo: null // watchCountryChange will set initial value
    }

    $scope.closeButtonLink = `events-show-parkers-show({eventId: ${eventId}, parkerId: ${parkerId} })`

    $scope.saveVehicle = function () {
      $scope.addingVehicle = true

      var vehicle = {
        makeCode: $scope.newVehicle.makeCode,
        color: $scope.newVehicle.color,
        state: $scope.newVehicle.state || '',
        licenseInfo: $scope.newVehicle.licenseInfo,
        countryCode: $scope.newVehicle.countryCode
      }

      Data.addVehicle(eventId, parkerId, vehicle).then(
        function (response) {
          $scope.addingVehicle = false
          $state
            .go('events-show-parkers-show', {
              eventId: eventId,
              parkerId: parkerId
            })
            .then(() => {
              $rootScope.$broadcast('reloadParker', response.data.vehicle.id)
            })
        },
        function (response) {
          $scope.addingVehicle = false
          Data.showErrorAlert(response.data)
        }
      )
    }

    function loadColors () {
      $scope.loadingColors = true

      Settings.getSettings().then(settings => {
        $scope.loadingColors = false
        $scope.availableColors = settings.availableVehicleColors
      })
    }

    function loadParker () {
      $scope.loadingParker = true

      return Data.searchParkers(eventId, { id: parkerId }).then(function (
        response
      ) {
        $scope.loadingParker = false

        var users = response.data.users

        if (!users[0]) {
          return $state.go('events-show-parkers-index')
        }

        $scope.selectedParker = users[0]

        var continent = getContinentFromCountry(
          $scope.selectedParker.lot.countryCode
        )
        if (continent) {
          $scope.availableCountries = continent.countries
        }

        $scope.newVehicle.countryCode = $scope.selectedParker.lot.countryCode
      })
    }

    function getContinentFromCountry (countryCode) {
      var continent = _.find($scope.continents, function (continent) {
        var country = _.find(continent.countries, { isoCode: countryCode })
        return Boolean(country)
      })

      return continent
    }

    function loadCountries () {
      $scope.loadingCountries = true

      return Settings.getCountries().then(function (continents) {
        $scope.loadingCountries = false
        $scope.continents = continents
      })
    }

    function watchCountryChange () {
      $scope.$watch('newVehicle.countryCode', function () {
        var countryCode = $scope.newVehicle.countryCode
        if (countryCode) {
          loadCountryDetails()
        }
      })
    }

    function loadCountryDetails () {
      $scope.loadingCountryDetails = true

      const { countryCode } = $scope.newVehicle

      Settings.getDetailedCountryData(countryCode).then(country => {
        $scope.loadingCountryDetails = false
        $scope.licenseInfoSchema = country.schemas.vehicleLicenseInfo

        const lotAddress = $scope.selectedParker.lot.address

        // Try to get some initial info based on the lot address, like
        // the state or province for example.
        const defaultLicenseInfo = _.mapValues(
          $scope.licenseInfoSchema.properties,
          (settings, key) => lotAddress[key] ? lotAddress[key] : null
        )

        // Also try to get some info from the current licenseInfo,
        // if the user fills licenseInfo and then changes the country.
        if ($scope.newVehicle.licenseInfo) {
          for (const key in $scope.newVehicle.licenseInfo) {
            const value = $scope.newVehicle.licenseInfo[key]
            const schemaProperty = $scope.licenseInfoSchema.properties[key]

            // Property exists in schema of new country and is enabled?
            if (schemaProperty && (!schemaProperty.enum || _.includes(schemaProperty.enum, value))) {
              defaultLicenseInfo[key] = value
            }
          }
        }

        $scope.newVehicle.licenseInfo = defaultLicenseInfo
        $scope.availableMakes = country.vehicleMakes
      })
    }

    function initialize () {
      loadCountries().then(function () {
        loadParker()
      })

      loadColors()

      watchCountryChange()
    }

    initialize()
  }
)
