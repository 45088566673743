
require('../templates/navigation.template.html')
require('../routes/user/login/user-login.template.html')
require('../routes/properties/index/properties-index.template.html')
require('../components/event-info/event-info.template.html')
require('../components/loading-indicator/loading-indicator.template.html')
require('../components/toggle-button/toggle-button.template.html')
require('../routes/events/show/events-show.template.html')
require('../routes/events/show/events-show-gates.template.html')
require('../routes/events/show/events-show-active-parkers.template.html')
require('../routes/events/show/parkers/index/events-show-parkers-index.template.html')
require('../routes/events/show/parkers/show/add-vehicle/events-show-parkers-show-add-vehicle.template.html')
require('../routes/events/show/parkers/show/events-show-parkers-show.template.html')
require('../routes/subscriptions/index/subscriptions-index.template.html')
require('../routes/subscriptions/select-organization/subscriptions-select-organization.template.html')
require('../routes/events/show/parkers/show/events-show-parkers-show-parker-gate.template.html')
require('../routes/lots/show/lots-show.template.html')
require('../modals/closeout-cashier/closeout-cashier.template.html')
