'use strict'
import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.controller(
  'UserLoginController',
  function (
    Authentication,
    Data,
    $stateParams,
    $location,
    $timeout,
    $window,
    $scope,
    $state,
    $i18next
  ) {
    $scope.step = 'login'
    $scope.email = $location.search().email || ''
    $scope.twoFactorCode = ''
    $scope.twoFactorRemember = false
    $scope.password = ''
    $scope.isLoading = false

    function getCredentialsObject () {
      var credentials = {
        email: $scope.email,
        password: $scope.password
      }

      return credentials
    }

    function redirect () {
      if ($stateParams.source) {
        $location.url($stateParams.source.replace('#!/', ''))
      } else {
        $location.url('/properties')
      }
    }

    function init () {
      if ($stateParams.logout) {
        Authentication.logout()
        $state.go('user-login')
      }

      $scope.isLoading = true

      if ($stateParams.token) {
        Authentication.setToken(
          $stateParams.token,
          redirect,
          function () {
            $scope.isLoading = false
          }
        )
      } else {
        Authentication.verifyAuthentication(
          redirect,

          function () {
            $scope.isLoading = false
          }
        )
      }
    }

    function showMessageSentAlert (method) {
      let message

      switch (method) {
        case 'sms':
          message = $i18next.t('login.twoFactorStep.resent.sms')
          break

        case 'call':
          message = $i18next.t('login.twoFactorStep.resent.call')
          break
      }

      if (message) {
        $timeout(function () {
          $window.alert(message)
        }, 50)
      }
    }

    $scope.forgotPassword = function () {
      $scope.password = ''
      $scope.resetPasswordField()

      $timeout(function () {
        if ($scope.email.trim() === '') {
          $window.alert($i18next.t('login.forgotPassword.emailRequired'))
          return
        }

        if (
          !$window.confirm(
            $i18next.t('login.forgotPassword.confirmation')
          )
        ) {
          return
        }

        $scope.isLoading = true

        Data.requestPasswordReset($scope.email).then(
          function (data) {
            $scope.isLoading = false

            $timeout(function () {
              $window.alert(data.success.message)
            }, 50)
          },
          function (response) {
            $scope.isLoading = false
            Data.showErrorAlert(response.data)
          }
        )
      })
    }

    $scope.resetPasswordField = function () {
      $scope.incorrectCredentials = false
    }

    $scope.submitLogin = function () {
      if (!$scope.email) {
        $scope.$broadcast('focusEmail')
        return
      }

      if (!$scope.password) {
        $scope.$broadcast('focusPassword')
        return
      }

      $scope.isLoading = true

      var credentials = getCredentialsObject()

      Authentication.tryAuthenticate(credentials).then(
        function success (data) {
          if (data.twoFactorAuthentication) {
            $scope.isLoading = false
            $scope.step = 'two-factor'
            $scope.twoFactorCode = ''
            $scope.$broadcast('focusTwoFactorCode')
          } else {
            redirect()
          }
        },

        function error (response) {
          $scope.isLoading = false

          if (response.status >= 400) {
            Data.showErrorAlert(response.data)
          } else {
            Data.showErrorAlert(null)
          }
        }
      )
    }

    $scope.twoFactorResend = function (method) {
      $scope.isLoading = true

      const credentials = getCredentialsObject()
      const data = { ...credentials, twoFactorAuthenticationMethod: method }

      Authentication
        .tryAuthenticate(data)
        .then(
          function success (response) {
            $scope.isLoading = false

            if (response.twoFactorAuthentication) {
              showMessageSentAlert(method)
            } else if (response.user) {
              redirect()
            }
          },

          function error (response) {
            $scope.isLoading = false
            Data.showErrorAlert(response.data)
          }
        )
    }

    $scope.submitTwoFactor = function () {
      $scope.isLoading = true

      var credentials = getCredentialsObject()
      credentials.twoFactorAuthenticationCode = $scope.twoFactorCode
      credentials.twoFactorAuthenticationTrustPermanently =
        $scope.twoFactorRemember

      Authentication.tryAuthenticate(credentials).then(
        function success (data) {
          if (data.user) {
            redirect()
          } else {
            Data.showErrorAlert(null)
          }
        },

        function error (response) {
          $scope.isLoading = false
          Data.showErrorAlert(response.data)
        }
      )
    }

    $scope.twoFactorResend = function (method) {
      $scope.isLoading = true

      const requestData = {
        ...getCredentialsObject(),
        twoFactorAuthenticationMethod: method
      }

      Authentication.tryAuthenticate(requestData).then(
        function success (data) {
          $scope.isLoading = false

          if (data.user) {
            redirect()
          } else if (data.twoFactorAuthentication) {
            showMessageSentAlert(method)
          }
        },

        function error (response) {
          $scope.isLoading = false
          Data.showErrorAlert(response.data)
        }
      )
    }

    init()
  }
)
