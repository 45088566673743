'use strict'

import angular from 'angular'
import AddressFormatter from '@citifyd/address-formatter'

const app = angular.module('citifydMonitoring')

app.directive('ctAddress',
  function () {
    return {
      restrict: 'E',
      transclude: true,

      template: '<span ng-bind-html="formattedAddress"></span>',

      scope: {
        entity: '=',
        format: '@',
        addressFieldName: '@',
        countryFieldName: '@'
      },

      controller: function ($scope) {
        $scope.$watchGroup(
          ['entity', 'format', 'addressFieldName', 'countryFieldName'],
          function () {
            var formattedAddress = ''

            if ($scope.entity) {
              var format = $scope.format || 'full'
              var addressFieldName = $scope.addressFieldName || 'address'
              var countryFieldName = $scope.countryFieldName || 'countryCode'

              var address = $scope.entity[addressFieldName]
              var country = $scope.entity[countryFieldName]

              formattedAddress = AddressFormatter.format(
                address,
                country,
                format
              )
                .map(function (line) {
                  return line.replace(/</g, '&lt;').replace(/>/g, '&gt;')
                })
                .join('<br>')
            }

            $scope.formattedAddress = formattedAddress
          }
        )
      }
    }
  }
)
