'use strict'
import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.directive('ctToggleButton', function () {
  return {
    restrict: 'E',
    templateUrl: '/components/toggle-button/toggle-button.template.html',

    scope: {
      selected: '=',
      onChange: '=',
      disabled: '='
    },

    controller: [
      '$scope',
      function ($scope) {
        if ($scope.selected === undefined) {
          $scope.selected = false
        }
      }
    ],

    link: function (scope, element) {
      var $button = element.click(function () {
        if (scope.disabled) {
          return
        }

        scope.selected = !scope.selected
        scope.$apply()

        if (typeof scope.onChange === 'function') {
          scope.onChange(scope.selected)
        }
      })

      scope.$watch('selected', function () {
        $button.toggleClass('toggle-button-selected', scope.selected)
      })
    }
  }
})
