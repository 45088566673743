'use strict'
import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.service('GpsMonitoring', function GpsMonitoringService ($rootScope) {
  var options = {
    enableHighAccuracy: true,
    maximumAge: Infinity
  }
  var gpsWatch

  function success (pos) {
    var crd = pos.coords

    console.log('Your current position is:')
    console.log('Latitude:' + crd.latitude)
    console.log('Longitude: ' + crd.longitude)
    console.log('More or less ' + crd.accuracy + ' meters.')

    $rootScope.$apply(function () {
      $rootScope.$broadcast('gpsUpdate', crd)
    })
  }

  function error (err) {
    console.warn('ERROR(' + err.code + '): ' + err.message)

    $rootScope.$apply(function () {
      $rootScope.$broadcast('gpsError', err)
    })
  }

  function start () {
    console.log('Starting GPS watch...')
    gpsWatch = navigator.geolocation.watchPosition(success, error, options)
  }

  function stop () {
    if (!gpsWatch) return

    console.log('Stopping GPS watch...')
    navigator.geolocation.clearWatch(gpsWatch)
    gpsWatch = null
  }

  return {
    start: start,
    stop: stop
  }
})
