'use strict'

import angular from 'angular'
import moment from 'moment'

const app = angular.module('citifydMonitoring')

app.factory('Helper', function HelperFactory (
  $i18next
) {
  const exports = {}

  exports.formatDuration = function (duration, unit) {
    duration = moment.duration(duration, unit)

    var asHours = duration.asHours()
    var asMinutes = duration.asMinutes()

    var str
    if (asHours >= 1) {
      str = $i18next.t('timeDuration.hoursAndMinutes', {
        hours: duration.hours(),
        minutes: Math.floor(asMinutes % 60)
      })
    } else {
      str = $i18next.t('timeDuration.minutes', {
        minutes: Math.floor(asMinutes)
      })
    }

    return str
  }

  return exports
})
