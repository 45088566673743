import libphonenumber from 'google-libphonenumber'
import angular from 'angular'
import _ from 'lodash'

var PNF = libphonenumber.PhoneNumberFormat
var phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

const app = angular.module('citifydMonitoring')

app.filter('phoneNumber', function () {
  return function (input) {
    var phoneNumber = _.get(input, 'phoneNumber') || input
    var countryCode =
      _.get(input, 'phoneCountryCode') || _.get(input, 'countryCode')

    if (!phoneNumber) {
      return ''
    }

    var formattedPhoneNumber

    var number
    if (!countryCode || _.includes(['us', 'ca'], countryCode)) {
      countryCode = countryCode || 'us'
      number = phoneUtil.parseAndKeepRawInput(
        phoneNumber,
        countryCode.toUpperCase()
      )
      formattedPhoneNumber = phoneUtil.format(number, PNF.NATIONAL)
    } else {
      number = phoneUtil.parseAndKeepRawInput(
        phoneNumber,
        countryCode.toUpperCase()
      )
      formattedPhoneNumber = phoneUtil.format(number, PNF.INTERNATIONAL)
    }

    return formattedPhoneNumber
  }
})
