'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.controller('EventsShowGateController',
  function (CitifydModal, Data, $stateParams, $timeout, $scope, $log) {
    var eventId = $stateParams.eventId
    $scope.eventId = eventId

    var refreshTimer
    var lastUpdate
    var scopeDestroyed = false

    $scope.closeoutCashier = function () {
      var params = {
        eventId: eventId,
        eventGateId: $scope.selectedGate.eventGate.id,
        eventGateName: $scope.selectedGate.eventGate.name
      }

      CitifydModal.open('closeout-cashier', params, function (result) {
        if (result && result.action === 'saved') {
          $scope.loadedCashierCloseouts = false
          loadCashierCloseouts()
        }
      })
    }

    function updateCashierCloseouts (closeouts) {
      _.forEach(closeouts, function (closeout) {
        var index = _.findIndex($scope.cashierCloseouts, { id: closeout.id })

        closeout.createdAt = moment(closeout.createdAt).tz($scope.eventTimezoneName)

        if (index !== -1) {
          $scope.cashierCloseouts[index] = closeout
        } else {
          $scope.cashierCloseouts.push(closeout)
        }
      })
    }

    function loadCashierCloseouts () {
      $timeout.cancel(refreshTimer)

      if (scopeDestroyed) {
        return
      }

      var options = {}

      if (lastUpdate) {
        options.updatedAfter = lastUpdate
      }

      Data.getGateInfo(eventId, $scope.selectedGate.eventGate.id, options)
        .then(
          function (response) {
            $scope.loadedCashierCloseouts = true

            lastUpdate = response.data.lastUpdate
            updateCashierCloseouts(response.data.closeouts)
          },

          function () {
            $log.error('Error retrieving summary')
          }
        )
        .finally(function () {
          refreshTimer = $timeout(loadCashierCloseouts, 3000)
        })
    }

    function init () {
      $scope.loadedCashierCloseouts = false
      $scope.cashierCloseouts = []

      loadCashierCloseouts()

      $scope.$on('$destroy', function () {
        scopeDestroyed = true
        $log.info('Event gate scope destroyed')
        $timeout.cancel(refreshTimer)
      })
    }

    init()
  }
)
