'use strict'
import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.controller('IndexController', [
  'Authentication',
  '$state',
  (Authentication, $state) => {
    if (Authentication.isLoggedIn()) {
      $state.go('properties-list')
    } else {
      $state.go('user-login')
    }
  }
])
