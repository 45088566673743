'use strict'
import angular from 'angular'
import moment from 'moment'

const app = angular.module('citifydMonitoring')

app.factory('StateHolder', function StateHolderFactory (
  $rootScope,
  $log,
  $location,
  $window,
  $interval
) {
  var IN_DEBUG_MODE = false
  var LAST_PAGE_KEY = 'lastPage'
  var LAST_PAGE_UPDATE_KEY = 'lastPageUpdate'

  // If the app is kept closed for this amount of time (in seconds), the state will expire.
  var EXPIRATION_TIME = 60 * 5

  // Specifies if StateHolder has been started -- it can only be called once
  var started = false

  function now () {
    return parseInt(moment().format('X'), 10)
  }

  function loadSavedState () {
    var lastPage = $window.localStorage.getItem(LAST_PAGE_KEY)
    var lastPageUpdate = $window.localStorage.getItem(LAST_PAGE_UPDATE_KEY)
    var expiredState =
      !lastPageUpdate || parseInt(lastPageUpdate, 10) < now() - EXPIRATION_TIME

    if (lastPage && !expiredState) {
      var parts = lastPage.split('?')

      $location.path(parts[0])
      $location.search(parts.slice(1).join(''))

      return true
    }

    return false
  }

  function updateState () {
    if (IN_DEBUG_MODE) {
      $log.info('StateHolder: updating state...')
    }

    $window.localStorage.setItem(LAST_PAGE_KEY, $location.url())
    touch()
  }

  function touch () {
    if (IN_DEBUG_MODE) {
      $log.info('StateHolder: touching...')
    }

    $window.localStorage.setItem(LAST_PAGE_UPDATE_KEY, now())
  }

  function setStateUpdateHandlers () {
    $rootScope.$on('$stateChangeSuccess', updateState)
    $interval(touch, 5000)
  }

  function start () {
    if (started || !$window.localStorage) {
      return
    }

    started = true

    loadSavedState()
    setStateUpdateHandlers()
  }

  return {
    start: start
  }
})
