'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydMonitoring')

app.factory('Settings', function SettingsFactory (ENV, $http, $q, $i18next) {
  let _data = {}

  function getDataObject () {
    const language = $i18next.i18n.language

    if (!_data[language]) {
      _data[language] = {
        loadingCountries: false,
        loadingSettings: false,

        settingsCache: null,
        settingsCalls: [],

        countriesCache: null,
        countriesCalls: [],

        detailedCountriesCache: {}
      }
    }

    return _data[language]
  }

  function getSettings () {
    loadSettings()

    let deferred = $q.defer()
    let data = getDataObject()

    if (data.settingsCache) {
      deferred.resolve()
    } else {
      data.settingsCalls.push(deferred)
    }

    return deferred.promise.then(() => _.cloneDeep(data.settingsCache))
  }

  function getCountries () {
    loadCountries()

    let deferred = $q.defer()
    let data = getDataObject()

    if (data.countriesCache) {
      deferred.resolve()
    } else {
      data.countriesCalls.push(deferred)
    }

    return deferred.promise.then(() => _.cloneDeep(data.countriesCache))
  }

  function getDetailedCountryData (isoCode) {
    let data = getDataObject()

    if (data.detailedCountriesCache[isoCode]) {
      var deferred = $q.defer()
      deferred.resolve(data.detailedCountriesCache[isoCode])
      return deferred.promise
    } else {
      return $http.get(`${ENV.apiUrl}/countries/${isoCode}`)
        .then(function (response) {
          var country = response.data.country
          data.detailedCountriesCache[isoCode] = country
          return country
        })
    }
  }

  function loadSettings () {
    let data = getDataObject()

    if (data.loadingSettings) {
      return
    }

    data.loadingSettings = true

    $http.get(`${ENV.apiUrl}/settings`).then(
      function (response) {
        data.settingsCache = response.data

        var call
        while ((call = data.settingsCalls.shift())) {
          call.resolve()
        }
      },

      function () {
        setTimeout(function () {
          data.loadingSettings = false
          loadSettings()
        }, 500)
      }
    )
  }

  function loadCountries () {
    let data = getDataObject()

    if (data.loadingCountries) {
      return
    }

    data.loadingCountries = true

    $http.get(`${ENV.apiUrl}/countries`).then(
      function (response) {
        data.countriesCache = response.data.continents

        var call
        while ((call = data.countriesCalls.shift())) {
          call.resolve()
        }
      },

      function () {
        setTimeout(function () {
          data.loadingCountries = false
          loadCountries()
        }, 500)
      }
    )
  }

  function preloadCache () {
    loadSettings()
    loadCountries()
  }

  return {
    preloadCache,
    getSettings,
    getCountries,
    getDetailedCountryData
  }
})
