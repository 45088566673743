'use strict'

import angular from 'angular'

const app = angular.module('citifydMonitoring')

app.controller('SubscriptionsSelectOrganizationController',
  function (
    $scope,
    Data
  ) {
    function init () {
      $scope.isLoading = true
      $scope.organizations = []

      Data
        .getOrganizations()
        .then(response => {
          $scope.isLoading = false
          $scope.organizations = response.data.organizations
        })
    }

    init()
  }
)
