'use strict'
import angular from 'angular'
import _ from 'lodash'
import moment from 'moment'

const app = angular.module('citifydMonitoring')

app.controller('LotsShowController',
  function (ENV, Helper, Data, $scope, $location, $stateParams, $q, $i18next, $timeout) {
    var lotId = $stateParams.lotId
    $scope.lotId = lotId
    var stopped = false
    var refreshTimer

    $scope.boldStart = '<strong>'
    $scope.boldEnd = '</strong>'

    $scope.showMonthlyPasses = false
    $scope.showOndemandPasses = false
    $scope.activeMonthlyReservations = []

    $scope.reservations = {
      all: {
        active: [],
        expired: []
      },
      filtered: {
        active: [],
        expired: []
      }
    }

    $scope.shouldShowSearchField = function () {
      // once it's enabled, search field should keep appearing even if amount of
      // reservations decrease below the threshold
      if ($scope.searchFieldEnabled) {
        return true
      }

      if ($scope.reservations.all.active.length + $scope.reservations.all.expired.length > 0) {
        $scope.searchFieldEnabled = true
        return true
      }

      return false
    }

    $scope.searchFilled = function () {
      return ($scope.searchQuery || '').trim() !== ''
    }

    $scope.setUpFilteredReservations = function () {
      const words = ($scope.searchQuery || '')
        .toLowerCase()
        .split(' ')
        .filter(w => w !== '')

      const filter = reservation => {
        if (!words.length) {
          return true
        }

        const filterable = [
          (_.get(reservation, 'vehicle.make') || '').toLowerCase(),
          (_.get(reservation, 'vehicle.translatedColor') || '').toLowerCase(),
          (_.get(reservation, 'vehicle.license') || '').toLowerCase()
        ]

        for (const word of words) {
          for (const f of filterable) {
            if (f.indexOf(word) !== -1) {
              return true
            }
          }
        }
      }

      $scope.reservations.filtered.active = $scope.reservations.all.active.filter(filter)
      $scope.reservations.filtered.expired = $scope.reservations.all.expired.filter(filter)
    }

    function populateTimeLeftTo (reservation) {
      if (_.isArray(reservation)) {
        reservation.forEach(r => populateTimeLeftTo(r))
      } else {
        let timeLeft = null

        if (reservation.expirationTime) {
          if (moment().isAfter(reservation.expirationTime)) {
            timeLeft = $i18next.t('lots.show.activeParkers.values.timeLeft.expired')
          } else {
            const duration = moment.duration(moment(reservation.expirationTime).diff(moment()))
            timeLeft = Helper.formatDuration(duration)
          }
        }

        reservation.timeLeft = timeLeft
      }

      return reservation
    }

    function init (isReload) {
      $timeout.cancel(refreshTimer)

      var promises = []

      if (!isReload) {
        $scope.isLoading = true

        var getLotPromise = Data.getLotInfo(lotId)

        getLotPromise.then(function (response) {
          $scope.lot = response.data.lot
          $scope.plan = _.find($scope.lot.plans, { period: 'month' })
        })

        promises.push(getLotPromise)
      } else {
        $scope.isRefreshing = true
      }

      var getLotSchedulePromise = Data.getLotSchedule(
        lotId,
        moment().format(),
        moment().format()
      )
      var getLotSubscriptions = Data.getLotSubscriptions(lotId)

      getLotSubscriptions.then(function (response) {
        $scope.subscriptions = response.data.subscriptions
      })

      // we first need to find which availability is happenign right now at the lot...
      getLotSchedulePromise = getLotSchedulePromise
        .then(function (response) {
          $scope.currentAvailability = _.last(response.data.availabilities)

          if (!$scope.currentAvailability) return

          $scope.spaces = _.find(
            $scope.currentAvailability.lots.find(
              c => c.id.toString() === lotId.toString()
            ).spaces,
            { name: 'regular' }
          )

          // after getting the id, let's find all reservations for this availability...
          return Data.getLotReservations({
            lotId,
            limit: 100,
            includeRecentlyExpired: true
          })
        })
        .then(function (response) {
          if (!response) return

          const reservations = populateTimeLeftTo(response.data.reservations)
          const now = moment()

          $scope.reservations.all.active = _.filter(reservations, r => r.endTime === null && (!r.expirationTime || now.isBefore(r.expirationTime)))
          $scope.reservations.all.expired = _.difference(reservations, $scope.reservations.all.active)

          if (!$scope.shouldShowSearchField()) {
            $scope.searchQuery = ''
          }

          $scope.setUpFilteredReservations()

          // some easy filters
          $scope.activeMonthlyReservations = _.filter(
            $scope.reservations.all.active,
            r => r.subscriptionId
          )
          $scope.activeOnDemandReservations = _.filter(
            $scope.reservations.all.active,
            r => !r.subscriptionId
          )
        })

      promises.push(getLotSubscriptions)
      promises.push(getLotSchedulePromise)

      $q.all(promises).then(function () {
        $scope.isLoading = false
        $scope.isRefreshing = false
      })

      if (!stopped) {
        refreshTimer = $timeout(function () {
          init(true)
        }, ENV.refreshInterval)
      }
    }

    // TODO: put the methods below in a directive

    /* Helper methods for Monthly spaces allocation */
    $scope.hasReachedMaximumForMonthly = function (notInclusive) {
      return notInclusive
        ? $scope.planSpaces > $scope.lot.maxSpots
        : $scope.planSpaces >= $scope.lot.maxSpots
    }

    $scope.hasReachedMinimumForMonthly = function (notInclusive) {
      return notInclusive ? $scope.planSpaces < 1 : $scope.planSpaces <= 1
    }

    $scope.increaseMonthlyAllocation = function () {
      if ($scope.hasReachedMaximumForMonthly()) return

      $scope.planSpaces++
    }

    $scope.decreaseMonthlyAllocation = function () {
      if ($scope.hasReachedMinimumForMonthly()) return

      $scope.planSpaces--
    }

    $scope.savePlanSpaces = function () {
      if (
        $scope.hasReachedMaximumForMonthly(true) ||
        $scope.hasReachedMinimumForMonthly(true)
      ) { return }

      return Data.updateLotPlan($scope.plan.id, {
        spaces: $scope.planSpaces
      }).then(
        function () {
          $scope.showMonthlyPasses = false
          $scope.plan.spaces = angular.copy($scope.planSpaces)
        },
        function (errorResponse) {
          Data.showErrorAlert(errorResponse.data)
        }
      )
    }

    /* Helper methods for On-demand spaces allocation */
    $scope.hasReachedMaximumForAvailability = function (notInclusive) {
      return notInclusive
        ? $scope.availabilityMaxSpaces > $scope.lot.maxSpots
        : $scope.availabilityMaxSpaces >= $scope.lot.maxSpots
    }

    $scope.hasReachedMinimumForAvailability = function (notInclusive) {
      return notInclusive
        ? $scope.availabilityMaxSpaces < 1
        : $scope.availabilityMaxSpaces <= 1
    }

    $scope.increaseAvailabilityAllocation = function () {
      if ($scope.hasReachedMaximumForAvailability()) return

      $scope.availabilityMaxSpaces++
    }

    $scope.decreaseAvailabilityAllocation = function () {
      if ($scope.hasReachedMinimumForAvailability()) return

      $scope.availabilityMaxSpaces--
    }

    $scope.saveAvailabilitySpaces = function () {
      if (
        $scope.hasReachedMaximumForAvailability(true) ||
        $scope.hasReachedMinimumForAvailability(true)
      ) { return }

      return Data.updateAllocation(
        $scope.currentAvailability.id,
        $scope.lot.id,
        $scope.availabilityMaxSpaces
      ).then(
        function () {
          $scope.showOndemandPasses = false
          $scope.spaces.max = angular.copy($scope.availabilityMaxSpaces)
        },
        function (errorResponse) {
          Data.showErrorAlert(errorResponse.data)
        }
      )
    }

    init()

    // if the user is updating any of the values, prevent the automatic refresh from changing the spaces amount
    $scope.$watch('showMonthlyPasses', function (showMonthlyPasses) {
      if (showMonthlyPasses) {
        $scope.planSpaces = $scope.plan.spaces
      }
    })

    $scope.$watch('showOndemandPasses', function (showOndemandPasses) {
      if (showOndemandPasses) {
        $scope.availabilityMaxSpaces = $scope.spaces.max
      }
    })

    // when the user navigates to another page, stop refresh
    $scope.$on('$destroy', function () {
      stopped = true
      $timeout.cancel(refreshTimer)
    })
  }
)
