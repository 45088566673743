/* global APP_VERSION */

'use strict'

import './assets/styles/main.scss'

import fastclick from 'fastclick'
import angular from 'angular'
import _ from 'lodash'

require('./config/i18next.config')

require('angular-ui-router')
require('angular-animate')
require('angular-cookies')
require('angular-modal-service')
require('angular-moment')
require('angular-resource')
require('angular-route')
require('angular-sanitize')
require('angular-touch')
require('angular-qr')
require('angular-uuid')
require('ngmap')
require('ng-i18next')
require('tinycolor2') // required for colorpicker
require('angularjs-color-picker')

// Remove touch delay on iOS
if ('addEventListener' in document) {
  document.addEventListener(
    'DOMContentLoaded',
    function () {
      fastclick.attach(document.body)
    },
    false
  )
}

// setup the project
const app = angular.module('citifydMonitoring', [
  // External modules
  'ui.router',
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ngTouch',
  'angularMoment',
  'angularModalService',
  'ja.qr',
  'angular-uuid',
  'ngMap',
  'jm.i18next',
  'color.picker'
])

require('./config/template.config')
require('./services')
require('./helpers')
require('./components')
require('./modals')
require('./routes')

app.constant('ENV', {
  apiUrl: process.env.API_URL,
  name: process.env.ENV,
  appVersion: APP_VERSION,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  refreshInterval: process.env.REFRESH_INTERVAL,
  supportNumber: process.env.SUPPORT_NUMBER
})

app.run((ENV, Settings, $http, $i18next) => {
  const commonHeaders = $http.defaults.headers.common

  commonHeaders['Citifyd-app-version'] = `Live ${ENV.appVersion}`
  commonHeaders['Citifyd-accept-language'] = $i18next.i18n.language

  Settings.preloadCache()
})

app.config([
  '$urlRouterProvider',
  '$stateProvider',
  ($urlRouterProvider, $stateProvider) => {
    $urlRouterProvider.otherwise('/')

    const routes = {
      'events-show': {
        url: '/events/:eventId',
        views: {
          '': {
            templateUrl: '/routes/events/show/events-show.template.html',
            controller: 'EventsShowController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'events-show-parkers-search': {
        url: '/events/:eventId/parkers?{lotId:int}&{q:string}',
        views: {
          '@': {
            templateUrl:
              '/routes/events/show/parkers/index/events-show-parkers-index.template.html',
            controller: 'EventsShowParkersIndexController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'events-show-parkers-show-addVehicle': {
        url: '/events/:eventId/parkers/:parkerId/add-vehicle',
        views: {
          '@': {
            templateUrl:
              '/routes/events/show/parkers/show/add-vehicle/events-show-parkers-show-add-vehicle.template.html',
            controller: 'EventsShowParkersShowAddVehicleController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'events-show-parkers-show': {
        url: '/events/:eventId/parkers/:parkerId?fromEventList',
        views: {
          '@': {
            templateUrl:
              '/routes/events/show/parkers/show/events-show-parkers-show.template.html',
            controller: 'EventsShowParkersShowController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'subscriptions': {
        url: '/subscriptions?organizationId',
        views: {
          '@': {
            templateUrl: '/routes/subscriptions/index/subscriptions-index.template.html',
            controller: 'SubscriptionsIndexController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'subscriptions-select-organization': {
        url: '/subscriptions/select-organization',
        views: {
          '@': {
            templateUrl: '/routes/subscriptions/select-organization/subscriptions-select-organization.template.html',
            controller: 'SubscriptionsSelectOrganizationController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      home: {
        url: '/',
        template: '',
        controller: 'IndexController',
        auth: false
      },
      'lots-show': {
        url: '/lots/:lotId',
        views: {
          '': {
            templateUrl: '/routes/lots/show/lots-show.template.html',
            controller: 'LotsShowController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'properties-list': {
        url: '/properties',
        views: {
          '': {
            templateUrl:
              '/routes/properties/index/properties-index.template.html',
            controller: 'PropertiesIndexController',
            controllerAs: 'ctrl'
          }
        },
        auth: true
      },
      'user-login': {
        url: '/user/login?logout&source&token',
        params: { logout: null, source: null, token: null },
        views: {
          '@': {
            templateUrl: '/routes/user/login/user-login.template.html',
            controller: 'UserLoginController',
            controllerAs: 'ctrl'
          }
        },
        auth: false
      }
    }

    _.forEach(routes, (settings, routeName) => {
      let pageSettings = settings.templateUrl
      let routeConfig = _.omit(settings, ['templateUrl'])
      routeConfig = { ...routeConfig, ...pageSettings }
      $stateProvider.state(routeName, routeConfig)
    })
  }
])

app.run([
  '$window',
  '$rootScope',
  'Authentication',
  'StateHolder',
  '$state',
  function (
    $window,
    $rootScope,
    Authentication,
    StateHolder,
    $state
  ) {
    // Add "no-touch" class on body for devices that don't have touch screen
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.className += ' no-touch'
    }

    $rootScope.loadImage = function (image) {
      return require('./assets/images/' + image)
    }

    // If we're in standalone mode (which will be true when this is transformed into a iOS web app in the home screen),
    // then we want the load the app on its last state. If we don't do this, every time the app goes in background,
    // it is reloaded on the home page when the user returns to it.
    if ($window.navigator.standalone) {
      StateHolder.start()
    }

    // Check for authentication when route changes
    $rootScope.$on('$stateChangeStart', function (e, toState, params) {
      var requiresAuth = toState && toState.auth

      if (requiresAuth && !Authentication.isLoggedIn()) {
        var options = {}
        var url = $state.href(toState.name, params, { relative: true })

        if (toState.auth) {
          options.source = url
        }

        $state.go('user-login', options)

        e.preventDefault()
      }
    })

    $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      // Saves previous state info in root scope whenever it changes, so that it can be
      // used by pages to perform special logic based on previous page.
      $rootScope.previousState = {
        name: from.name,
        params: fromParams
      }
    })

    $rootScope.$on('$stateChangeError', function (
      event,
      toState,
      toParams,
      fromState,
      fromParams,
      error
    ) {
      console.error(event, toState, toParams, fromState, fromParams, error)
    })
  }
])
