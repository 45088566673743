'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './language-selector.template.html'
import './language-selector.style.scss'

const app = angular.module('citifydMonitoring')

app.directive('ctLanguageSelector', function () {
  return {
    restrict: 'E',
    templateUrl: templateUrl,
    scope: {},

    controller: (Authentication, Data, Languages, $rootScope, $scope) => {
      $scope.availableLanguages = Languages.getAvailable()
      $scope.currentLanguage = find(Languages.getCurrent())

      $scope.isOpen = false
      $scope.isApplyingLanguage = false

      function find (language) {
        return _.find($scope.availableLanguages, { key: language })
      }

      function updateUserLanguageAndRefresh (language) {
        $scope.isApplyingLanguage = true

        Data
          .updateCurrentUser({ language: language.key })
          .then(
            () => window.location.reload(),
            response => {
              $scope.isApplyingLanguage = false
              Data.showErrorAlert(response.data)
            }
          )
      }

      $scope.changeLanguage = language => {
        Languages.change(language.key, true)

        if (Authentication.isLoggedIn()) {
          updateUserLanguageAndRefresh(language)
        } else {
          $scope.toggle() // close menu
        }
      }

      $scope.toggle = () => {
        $scope.isOpen = !$scope.isOpen
      }

      $rootScope.$on('language-changed', (e, language) => {
        $scope.currentLanguage = find(language)
      })
    }
  }
})
